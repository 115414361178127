import React from 'react'
import {graphql, Link, navigate} from 'gatsby'
import SEO from '../components/SEO'
import CourseSummary from '../components/CourseSummary'
import Layout from '../components/Layout'
import ProductList from '../components/ProductList'
import PageSection from '../components/PageSection'
import SubCategoryListSq from '../components/SubCategoryList/SubCategorySquare'
import SubCategoryListImage from '../components/SubCategoryList/SubCategoryImage'
import RetryButton from '../components/RetryButton'

import {
	Breadcrumb,
	Icon,
	Container,
	Header,
	Message,
	Item
} from 'semantic-ui-react'
import * as styles from './styles/template.module.css'
import * as productstyles from '../components/ProductSummary/styles/productSum.module.css'
import {
	mobileMaxWidth,
	resizeTimer,
	tabletMaxWidth
} from '../globalVar'



const Negosyo = require('../../lib/negosyo')



class CoursePageTemplate extends React.PureComponent {
	state = {
		record:this.props.data.negosyoNow.viewCourse,
		pageLoading:true,
		error:false,
		relatedList:[],
		category:[],
		business:[],
		hasRegistered: false,
		allowFinalize: true,
		registerMessage: "Register Now",
		username: "",
		pageSection:this.props.data.negosyoNow.viewCourse.sections
	}


	componentDidMount() {
		Negosyo.isLoggedIn()
		.then((isLoggedIn)=>{
			if(isLoggedIn) {
				Negosyo.getCurrentUserDetails()
				.then((user)=>{
					this.handleSignIn(user.username);
				}).catch((err)=>{
					this.fetchData();
				})
			} else {
				this.fetchData();
			}
		}).catch((err)=>{
			this.fetchData();
		})

	}


	handleSignIn = (username) => {
		// Check if already registered
		if (username.length > 0) {
			this.setState({
				username: username,
			}, function() {
				(async () => {
					const response = await Negosyo.checkCourseRegistered(username, this.props.pageContext.url);
					if (response.hasOwnProperty("registered") && response.hasOwnProperty("message")  && response.hasOwnProperty("allowed")) {
						if (response.registered) {
							navigate('/course/resource/'+this.props.pageContext.url);
							return;
						}
						this.setState({
							hasRegistered: response.registered,
							allowFinalize: response.allowed,
							registerMessage: (response.allowed===false)?response.message:""
						}, function () {
							this.fetchData();
						});
					} else {
						this.fetchData();
					}
				})();
			});
		}
	}

	seeMore = () => {
		navigate('/course');
	}
	resoucesButton = () => {
		const {
			url
		} = this.props.pageContext

		navigate('/course/resource/'+url);
	}

	applyButton = () => {
		const {
			url
		} = this.props.pageContext

		navigate('/course/register/'+url);
	}

	fetchData = (retry=false) => {
		const {
			url
		} = this.props.pageContext

		Negosyo.getCourseDetails(url)
		.then((data)=>{
			this.setState({
				record:data,
				pageLoading:false,
				relatedList:data.related,
				category:data.category,
				business:data.business,
				pageSection:data.sections
			})
		}).catch((err)=>{
			this.setState({
				error:true,
				pageLoading:false
			})
		})
	}

	render() {
		const {
			record,
			error,
			pageLoading,
			shown,
			relatedList,
			category,
			business,
			pageSection
		} = this.state
		const {
			location
		} = this.props
		const {
			fetchData
		} = this
/*
		//https://developers.google.com/search/docs/data-types/product
		var structuredData = {
			"@context": "https://schema.org/",
			"@type": "Product",
			"name": product.name,
			"productID": product.sku,
			"sku": product.sku,
			"image": [
				product.mainImageHref,
			],
			"description": product.summary,
			//"review": {},
			//"aggregateRating": {},
			"offers": {
				"@type": "Offer",
				"url": location.href,
				"priceCurrency": product.meta.display_price.with_tax.currency.toUpperCase(),
				"price": product.meta.display_price.with_tax.amount,
				"itemCondition": "https://schema.org/NewCondition",
				"availability": product.available===true ? "https://schema.org/InStock":"https://schema.org/OutOfStock",
			}
		}
*/

		var isavailable = false;
		var completed = false;

		if (record!==undefined) {
			isavailable = record.available;
		}

		return (

			<Layout location={location} backButton={true}>
				<Container text className={styles.container}>
					<SEO
						canonicalpath={"/course/"+record.url}
						title={record===undefined ? "Course":record.name}
						image={record.mainImageHref}
						twitterCard={"summary"}
						description={record.summary}
						//structuredData={structuredData}
					/>
					<Breadcrumb className={styles.desktopOnly}>
						<Breadcrumb.Section content="Home" as={Link} to="/"/>
						<Breadcrumb.Divider />
						<Breadcrumb.Section content={"Courses"} as={Link} to={"/course/"} />
						<Breadcrumb.Divider />
						<Breadcrumb.Section content={record.name} />
					</Breadcrumb>
					<Breadcrumb className={styles.mobileOnly}>
						<Breadcrumb.Section as={Link} to={"/course/"} >
							<Icon name="arrow left" />
							{"Courses"}
						</Breadcrumb.Section>
					</Breadcrumb>
					<br/>

					{error===true  && (
						<RetryButton
							retryFunc={()=>{
								this.setState({
									error:false,
									pageLoading:true
								})
								fetchData(true)
							}}
							errorMessage={"Unable to load Course Information"}
						/>
					)}
					{( isavailable===false && pageLoading===false ) && (
						<Message warning className={styles.msg}>
							<Message.Header>
								Course no longer available.
							</Message.Header>
							Want this item back? Let us know through our <Link to={"/request-product/"} state={{productName:record.name}}>Request a Course</Link> form
						</Message>
					)}
					<CourseSummary
						{...record}
						subtitle={""}
						showTeaserVideo={true}
						expirationdate={""}
						hasRegistered={false}
					/>
					<Item.Group>
						<Item className={styles.item}>
							<Item.Content>

								{pageLoading ?
									<Item.Extra>
										<button
											className={"fluid ui button "+productstyles.disabledButton}
										> Loading...</button>
									</Item.Extra>

								:
									<>
										{this.state.hasRegistered?
												<Item.Extra>
													<button
														className={"fluid ui button "+productstyles.proceedButton} onClick={()=>this.resoucesButton()}
													> Already Registered: View Resources</button>
												</Item.Extra>
										:
											<>
												{completed ?<>
													<Item.Extra>
														<button
															className={"fluid ui button "+productstyles.disabledButton}
														>Registration Closed</button>
													</Item.Extra>

												</>:<>
													<Item.Extra>
															<button
																className={"fluid ui button "+productstyles.proceedButton} onClick={()=>this.applyButton()}
															> {this.state.registerMessage===""?"Register Now":this.state.registerMessage}</button>
														</Item.Extra>
													</>
												}
											</>
										}

									</>
								}
							</Item.Content>
						</Item>
					</Item.Group>
					<PageSection sectionList={pageSection} pageName={record.name} />
				</Container>
				<span id="page-related" className={styles.anchor}/>
				<Container className={styles.container}>
					{relatedList.length> 0 && (
						<React.Fragment>
							<Header className={`${styles.header}`}>
								Others also bought:
							</Header>
							<ProductList
								products={relatedList}
								shown={shown}
								grid={16}
								cameFrom={`course-${record.name}`}
								listName={`Related Course-${record.name}`}
							/>
						</React.Fragment>
					)}

					{category.length> 0 && (
						<React.Fragment>
							<Header className={`${styles.header}`}>
								See related items:
							</Header>
							<SubCategoryListImage
							  relatedcategorylist={category}
							  shown={shown}
							/>
						</React.Fragment>
					)}

					{business.length> 0 && (
						<React.Fragment>
							<Header className={`${styles.header}`}>
								Businesses that might interest you:
							</Header>
							<SubCategoryListSq
								relatedcategorylist={business}
								shown={shown}
								reverse={true}
								contentClassName={styles.BusinessListContent}
								headerClassname={styles.BusinessListHeader}
							/>
						</React.Fragment>
					)}
					<div className={"ui segment "+styles.container}>
						<button className={"fluid ui button "+styles.seemoreButton} onClick={()=>this.seeMore()}> Go Back </button>
					</div>
				</Container>
			</Layout>
		)
	}
}

export default CoursePageTemplate

export const data = graphql`
	query CourseQuery($url:String!) {
		negosyoNow {
			viewCourse( url: $url ) {
				id
				name
				summary
				url
				mainImageHref
				slug
				organizationName
				organizationUrl
				sku
				available
				fees
				sections {
					content
					externalLink
					imgurl
					internalLink
					items {
						type
						title
						internalLink
						imgurl
						externalLink
						content
					}
					type
					title
				}
			}
		}
	}
`



