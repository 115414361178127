import React, {useState, useEffect} from 'react'
import {Link, navigate} from 'gatsby'
import {Image, Item } from 'semantic-ui-react'
import Video from '../Video'
import * as styles from '../ProductSummary/styles/productSum.module.css'
import '../ProductSummary/styles/productSum.css'


const CourseSummary = ({
	name,
	mainImageHref,
	organizationName,
	organizationUrl,
	fees,
	subtitle,
	showTeaserVideo,
	videourl,
	validitydays,
	totalsec,
	numfiles,
	expirationdate,
	hasRegistered
}) => {

	const totaltimeinsec = typeof totalsec !== "undefined"?(isNaN(parseInt(totalsec,10))?0:parseInt(totalsec,10)):0;
	const numdownloadables = typeof numfiles !== "undefined"?(isNaN(parseInt(numfiles,10))?0:parseInt(numfiles,10)):0;
	const hasvideo = typeof videourl !== "undefined"?videourl.length > 0:false;
	const fulltitle = subtitle.length > 0?name+" "+subtitle:name;

	const curexpiredate = expirationdate.length >=10? expirationdate:"";
	const numvaliddays = expirationdate.length < 1?
			(isNaN(parseInt(validitydays,10))?-1:parseInt(validitydays,10))
		:
			expirationdate.length < 10? (isNaN(parseInt(expirationdate,10))?-1:parseInt(expirationdate,10)): -1;

	const expiredatestr = curexpiredate.length>0? new Intl.DateTimeFormat('en-US', { dateStyle: 'full'}).format(new Date(curexpiredate)):"";

	return (
		<React.Fragment>
			<Item.Image size="massive">
				{(hasvideo && showTeaserVideo) ?
					<Video src={videourl+"?autoplay=1&loop=1"} title={fulltitle} size={"wide"} />
				:
					<Image src={mainImageHref} alt={name} />
				}
			</Item.Image>
			<Item.Group>
				<Item className={styles.item}>
					<Item.Content>
						<Item.Header className={styles.header}><h1>{fulltitle}</h1></Item.Header>
						<Item.Description className={styles.text}>
							Presented by &nbsp;
							{organizationUrl.length > 0
							?
								<Link to={`/organization/${organizationUrl}/`} className={styles.link}>
									{organizationName}
								</Link>
							:
								<>{organizationName}</>
							}
							<table>
								{totaltimeinsec > 0 && <tr>
									<td>Videos:</td>
									<td>{
										(totaltimeinsec>3600?Math.floor(totaltimeinsec/3600)+"h":"")
										+
										(totaltimeinsec>60?Math.floor((totaltimeinsec%3600)/60)+"m":"")
										+
										(totaltimeinsec%60>0 && ((totaltimeinsec%60)+"s"))
									}</td>
								</tr>}
								{numdownloadables > 0 && <tr>
									<td>Downloads:</td>
									<td>{numdownloadables+" Files"}</td>
								</tr>}
								{(fees.length > 0 && hasRegistered=== false) && <tr>
									<td>Fees:</td>
									<td>{fees}</td>
								</tr>}
								{expiredatestr.length>0 ? <tr>
									<td>Valid until:</td>
									<td>{expiredatestr}</td>
								</tr>
								:<>
									{numvaliddays>0 && <tr>
										<td>Validity Period:</td>
										<td>{numvaliddays} Days {numvaliddays>=30?"(approx. "+Math.floor(numvaliddays/15)/2+" months)":""} after first view/download</td>
									</tr>}
								</>}
							</table>
						</Item.Description>
					</Item.Content>
				</Item>
			</Item.Group>

		</React.Fragment>
	)
}

export default CourseSummary;
